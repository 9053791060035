import { Suspense, lazy, useState } from 'react';
import { 
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import { useLocation,Link } from "wouter"
import './App.scss';

const Home = lazy(() => import('./pages/home'));
const Tesla = lazy(() => import('./pages/vehicle/tesla'));
const Shoes = lazy(() => import('./pages/clothing/shoe/shoe'));
const Aesop = lazy(() => import('./pages/cosmetics/aesop'));
const Arc = lazy(() => import('./pages/electronics/arc'));
const Dji = lazy(() => import('./pages/electronics/dji'));
const ChinaSilkMuseum = lazy(() => import('./pages/art/ChinaSilkMuseum/index'));
const ChangshaMuseum = lazy(() => import('./pages/art/ChangShaMuseum/index'))
const ThreeGraces = lazy(() => import('./pages/art/ThreeGraces'));
const Dragon = lazy(() => import('./pages/art/Dragon'));
const AppleWatch = lazy(() => import('./pages/electronics/apple-watch'));
const AppleMacbookM1 = lazy(() => import('./pages/electronics/macbook-m1'));
const BoseSoundLinkMini = lazy(() => import('./pages/electronics/bose-soundlink-mini'));
const Gallery = lazy(() => import("./pages/art/gallery"));
const ARMask = lazy(() => import("./pages/clothing/mask/"));
const NAOZ = lazy(() => import('./pages/building/naoz'));

const menuItems = [
	{ title: '全部', icon: 'iconall' },
	{ title: '艺术与设计', icon: 'iconart' },
	{ title: '3C数码', icon: 'icondigital' },
	{ title: '家装与家具', icon: 'iconfurniture' },
	{ title: 'IOT与数字孪生', icon: 'iconiot' },
	{ title: '鞋包服饰', icon: 'iconclothing' }
];

export default function App(props) {

    const [ location ] = useLocation();
	const [active, setActive] = useState( 0 );

  	return (
		<main className="app">
			<div className="navigation">
				<a href="/" className="home">
					<div className="logo">
						<i className="iconfont iconsxtlogo"></i>
					</div>
				</a>
				<div className="menu">
					{menuItems.map((child, index) => {
						return <div className={ 'menu-item' + (index === active ? ' active': '')} onClick={() => {
							setActive( index );
						}}>
							<i className={ 'iconfont ' + child.icon }></i>{child.title}
						</div>
					})}
				</div>
			</div>
			<article>
				<div className="page"> 
					{
						location !== '/' && <a href="/">
							<div className="back">
								<i className="iconfont iconarrow"></i>
							</div>
						</a>
					}
					<Router>
						<Suspense fallback={<div className="route-loading">
							正在加载...
						</div>}>
							<Switch>
								<Route path="/" exact component={Home} />
								{/* 交通工具 */}
								<Route path="/vehicle/tesla" component={Tesla} />
								{/* 服装类 */}
								<Route path="/clothing/shoes" component={Shoes} />
								<Route path="/clothing/mask" component={ARMask} />	
								{/* 化妆品 */}
								<Route path="/cosmetics/aesop" component={Aesop} />
								{/* 电器 */}
								<Route path="/electronics/dji" component={Dji} />
								<Route path="/electronics/arc" component={Arc} />
								<Route path="/electronics/bose-soundlink-mini" component={BoseSoundLinkMini} />
								<Route path="/electronics/apple-watch" component={AppleWatch} />
								<Route path="/electronics/apple-mackbook-m1" component={AppleMacbookM1} />
								{/* 艺术 */}
								<Route path="/art/china-silk-museum" component={ChinaSilkMuseum} />
								<Route path="/art/changsha-museum" component={ChangshaMuseum} />
								<Route path="/art/three-graces" component={ThreeGraces} />
								<Route path="/art/dragon" component={Dragon} />
								<Route path="/art/gallery" component={Gallery} />
								{/* IOT与建筑 */}
								<Route path="/building/naoz" component={NAOZ} />
							</Switch>	
						</Suspense>
					</Router>
				</div>
			</article>
			{/* <footer>
				<div className="description">
					我们的目标是提供令人惊叹的体验，为品牌、技术、娱乐、艺术和文化创造战略价值。				
				</div>
				<div className="concat">
					<i className="iconfont icontubiao210"></i>&nbsp;+86 18058810726
					&nbsp;&nbsp;
					<i className="iconfont iconmail"></i>&nbsp;zzl11081@gmail.com
				</div>
			</footer> */}
		</main>
  	)
}
